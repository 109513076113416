import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/units-for-css-font-size",
  "date": "2014-04-11",
  "title": "UNITS FOR CSS FONT-SIZE",
  "author": "admin",
  "tags": ["development", "css"],
  "featuredImage": "feature.jpg",
  "excerpt": "Fonts sizes in CSS can be measured in four different units adding confusion to the web developer on top of having to deal with different browsers. Which of these four units is the best? Finding a definitive answer is difficult."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><img parentName="p" {...{
        "src": "/images/2014-04-11-units-for-css-font-size/iphone-fonts.png",
        "alt": "iPhone fonts"
      }}></img></p>
    <h2>{`Units`}</h2>
    <h1><em parentName="h1">{`Ems`}</em>{` (em): The `}<em parentName="h1">{`em`}</em>{` is a scalable unit that is used in web document media. An em is equal to the current font-size, for instance, if the font-size of the document is 12pt, 1em is equal to 12pt. Ems are scalable, so 2em would equal 24pt, .5em would equal 6pt, etc. Ems are becoming increasingly popular in web documents due to scalability and their mobile-device-friendly nature.`}</h1>
    <h1>{`Pixels (px): Pixels are fixed-size units that are used in screen media (i.e. to be read on the computer screen). One pixel is equal to one dot on the computer screen (the smallest division of your screen’s resolution). Many web designers use pixel units in web documents to produce a pixel-perfect representation of their site as it is rendered in the browser. One problem with the pixel unit is that it does not scale upward for visually-impaired readers or downward to fit mobile devices.`}</h1>
    <h1>{`Points (pt): Points are traditionally used in print media (anything that is to be printed on paper, etc.). One point is equal to 1/72 of an inch. Points are much like pixels, in that they are fixed-size units and cannot scale in size.`}</h1>
    <h1>{`Percent (%): The per cent unit is much like the `}<em parentName="h1">{`em`}</em>{` unit, save for a few fundamental differences. First and foremost, the current font-size is equal to 100% (i.e. 12pt = 100%). While using the percent unit, your text remains fully scalable for mobile devices and for accessibility.`}</h1>
    <h2>{`Differences`}</h2>
    <p>{`Units differ from each other, 1em = 12pt = 16px = 100%. When using these font-sizes, both the em and per cent units get larger as the base font-size increases, but pixels and points do not. It can be easy to set an absolute size for your text, but it’s much easier on your visitors to use scalable text that can display on any device or any machine. For this reason, the em and per cent units are preferred for web document text.`}</p>
    <h2>{`Em vs. %`}</h2>
    <p>{`We’ve decided that point and pixel units are not necessarily best suited for web documents, which leaves us with the em and per cent units. In theory, both the em and the per cent units are identical, but in application, they have a few minor differences that are important to consider.
In the example above, we used the per cent unit as our base font-size (on the body tag). If you change your base font-size from percent to ems(i.e. body { font-size: 1em; }), you `}<em>{`probably`}</em>{` won’t notice a difference. Let’s see what happens when `}<em parentName="p">{`1em`}</em>{` is our body font-size, and when the client alters the `}<em parentName="p">{`Text Size`}</em>{` setting of their browser (this is available in some browsers, such as Internet Explorer).
When the client’s browser text size is set to `}<em parentName="p">{`medium,`}</em>{` there is no difference between ems and per cent. When the setting is altered, however, the difference is quite large. On the `}<em parentName="p">{`Smallest`}</em>{` setting, ems are much smaller than per cent, and when on the `}<em parentName="p">{`Largest`}</em>{` setting, it’s quite the opposite, with ems displaying much larger than per cent. While some could argue that the em units are scaling as they are truly intended, in practical application, the em text scales too abruptly, with the smallest text becoming hardly legible on some client machines.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The em unit is the upcoming standard for font sizes on the web, but in practice, the per cent unit seems to provide a more consistent and accessible display for users. When client settings have changed, per cent text scales at a reasonable rate, allowing designers to preserve readability, accessibility, and visual design.
when you create a new design,you can use percent on the `}<inlineCode parentName="p">{`body`}</inlineCode>{` element (`}<inlineCode parentName="p">{`body { font-size: 62.5%; }`}</inlineCode>{`), and then use the `}<inlineCode parentName="p">{`em`}</inlineCode>{` unit to size it from there. As long as the `}<inlineCode parentName="p">{`body`}</inlineCode>{` is set using the per cent unit, you may choose to use either per cent or ems on any other CSS rules and selectors and still retain the benefits of using per cent as your base font size.
So the percent(%) is the best choice.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      